import React, { useState } from "react";
import * as styles from "./index.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";

export const SampleReportBanner: React.FC = () => {
  const [isVisible, setVisibility] = useState<boolean>(true);
  const removeBanner = () => {
    setVisibility(false);
  };

  const data: GatsbySampleReportBannerComponentQuery = useStaticQuery(graphql`
    query SampleReportBannerComponent {
      assessment_banner: file(
        relativePath: { eq: "assessment/sample_report_banner.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      close: file(
        relativePath: { eq: "assessment/banner_close.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <div
      className={styles.SampleReportBanner}
      style={isVisible ? {} : { display: "none" }}
    >
      <a
        className={styles.SampleReportBanner_Link}
        href="/assessment/sample_report"
      >
        <OptionalGatsbyImage
          className={styles.SampleReportBanner_Image}
          data={data.assessment_banner}
          alt=""
        />
      </a>
      <button
        type="button"
        className={styles.SampleReportBanner_DeleteButton}
        id="ts-delete-button"
        onClick={removeBanner}
      >
        <OptionalGatsbyImage
          className={styles.SampleReportBanner_DeleteButtonImage}
          data={data.close}
          alt=""
        />
      </button>
    </div>
  );
};
