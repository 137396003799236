import React from "react";
import { withContext } from "~context";
import { useStaticQuery, graphql } from "gatsby";
import * as styles from "./index.module.scss";
import { PageLayout } from "~components/templates/page-layout";
import { SampleReportBanner } from "~components/assessment/sample_report_banner";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";

const Component: React.FC = () => {
  const CONTACT_FORM_ID =
    "1uMD3hrl5l496OlYEawYvgryrXqwhjr1UxnNIbGBj2a3EQL7wAyiH42ueleZ4voe7";
  const ALTERNATIVE_GOOGLEFORM_URL = "https://forms.gle/B7c1hGh9i4rmJnEg6";

  const data: GatsbyPriceDocPageQuery = useStaticQuery(graphql`
    query PriceDocPage {
      catch: file(
        relativePath: { eq: "assessment/price_doc_catch.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <>
      <SampleReportBanner />
      <PageLayout
        paddingPattern="fit-header"
        meta={{
          title: "セキュリティ診断 料金概要資料ダウンロード",
          description:
            "Flatt Securityのセキュリティ診断の料金概要資料をダウンロード可能です。",
        }}
      >
        <div className={styles.DocContact_Wrapper}>
          <AssessmentDocContact />
        </div>
      </PageLayout>
    </>
  );
};

export default withContext(Component);
